<template>
	<div class="login" v-if="ifShow">
		<div class="logo-box">
			<img src="@/assets/images/logo.png" />
		</div>
		<div class="login-bg-box">
			<template v-if="showCode">
				<input type="text" placeholder="请输入姓名" v-model="regInfo.user_name" />
				<input type="number" placeholder="请输入手机号" v-model="regInfo.user_phone" />
				<div class="flex">
					<input type="number" placeholder="请输入验证码" class="code-input" v-model="regInfo.captcha" />
					<div :class="['get-code',{'disable':isDisabled}]" @click="sendCode">{{ buttonText }}</div>
				</div>
			</template>

			<template v-else>
				<input type="text" placeholder="请输入姓名" :value="userInfo.user_name" disabled />
				<input type="number" placeholder="请输入手机号" :value="userInfo.user_phone" disabled />
			</template>

			<div class="agree-row">
				<div :class="['check',{'active': isCheck}]" @click="changeCheck"></div>
				<span @click="changeCheck">已阅读并同意</span>
				<a @click="showCover = true">《会员服务协议》</a>
			</div>
			<div class="btn" @click="postData">提交</div>
		</div>
		<van-overlay :show="showCover" @click="showCover = false" :lock-scroll="false">
			<div class="wrapper" @click.stop>
				<apply-promoter types="4" :isChangeTitle="false"></apply-promoter>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import {
		bindTeam,
		sendsmsBindUser,
		updataInviteUser,
		getUser
	} from "@/http/api";
	import ApplyPromoter from "./applypromoter";
	export default {
		name: "",
		components: {
			ApplyPromoter
		},
		props: {},
		data() {
			return {
				userToken: "",
				userInfo: {},
				share_user_id: "0",
				share_code: "0",

				ifShow: false,
				isCheck: false,

				regInfo: {
					user_name: "",
					user_phone: "",
					captcha: ""
				},
				showCode: false,
				buttonText: "发送验证码",
				isDisabled: false,
				flag: true,
				showCover: false
			};
		},
		created() {
			let route = this.$route.query;
			this.userToken = this.$LStorage.getItem("userToken").user_token;
			this.userInfo = this.$LStorage.getItem("userInfo");
			if (route.share_user_id) {
				this.share_user_id = route.share_user_id
			}
			if (route.share_code) {
				this.share_code = route.share_code
			}
		},
		mounted() {
			if (this.userInfo.user_types == 2) {
				this.$router.replace({
					path: "/reg/success?types=1"
				});
			} else {
				if (this.userInfo.user_phone == "") {
					this.showCode = true;
				} else {
					this.showCode = false;
				}
			}
			this.ifShow = true;
		},
		methods: {
			// 获取用户个人信息
			async getUserInfo() {
				const res = await getUser({
					data: {
						user_token: this.userToken
					}
				});
				if (res.code == 200) {
					this.$LStorage.setItem("userInfo", res.data);
				}
			},

			// 选择协议
			changeCheck() {
				this.isCheck = !this.isCheck;
			},

			// 验证手机号
			checkMobile(str) {
				let re = /^1[3456789]\d{9}$/;
				if (re.test(str)) {
					return true;
				} else {
					return false;
				}
			},

			// 发送验证码
			sendCode() {
				let tel = this.regInfo.user_phone;
				if (tel == "") {
					this.$toast("请输入手机号");
					return;
				} else if (!this.checkMobile(tel)) {
					this.$toast("手机号格式有误");
					return;
				} else {
					let time = 60;
					this.buttonText = "已发送";
					this.isDisabled = true;
					if (this.flag) {
						this.sendMsg();
						this.flag = false;
						let timer = setInterval(() => {
							time--;
							this.buttonText = time + " 秒";
							if (time === 0) {
								clearInterval(timer);
								this.buttonText = "重新获取";
								this.isDisabled = false;
								this.flag = true;
							}
						}, 1000);
					}
				}
			},

			// 提交注册
			postData() {
				let info = this.regInfo,
					isCheck = this.isCheck;
				if (this.showCode) {
					if (info.user_name == "") {
						this.$toast("请输入您的姓名");
						return;
					} else if (info.user_phone == "") {
						this.$toast("请输入手机号");
						return;
					} else if (!this.checkMobile(info.user_phone)) {
						this.$toast("手机号格式有误");
						return;
					} else if (info.captcha == "") {
						this.$toast("请输入验证码");
						return;
					} else if (info.captcha.length != 4) {
						this.$toast("请输入四位数验证码");
						return;
					} else if (!isCheck) {
						this.$toast("请阅读并同意协议");
						return;
					} else {
						this.updataInviteUser();
					}
				} else {
					if (!isCheck) {
						this.$toast("请阅读并同意协议");
						return;
					} else {
						this.bindTeam()
					}
				}
			},

			// 注册达人（更新用户信息）
			async updataInviteUser() {
				const res = await updataInviteUser({
					data: {
						user_token: this.userToken,
						user_name: this.regInfo.user_name,
						user_phone: this.regInfo.user_phone,
						captcha: this.regInfo.captcha
					}
				});
				if (res.code == "200") {
					this.bindTeam()
				} else {
					this.$toast(res.msgs);
				}
			},

			// 绑定用户团队
			async bindTeam() {
				const res = await bindTeam({
					data: {
						user_token: this.userToken,
						parent_id: this.share_user_id,
						share_code: this.share_code
					}
				});
				if (res.code == "200") {
					this.$toast.success("注册成功");
					this.$router.replace({
						path: "/reg/success?types=2"
					});
				} else {
					this.$toast(res.msgs);
				}
			},

			// 发短信
			async sendMsg() {
				const res = await sendsmsBindUser({
					data: {
						user_phone: this.regInfo.user_phone
					}
				});
				if (res.code == "200") {
					// this.$toast('修改成功')
				} else {
					this.$toast.fail(res.msgs);
				}
			}
		}
	};
</script>

<style scoped lang="scss">
	.flex {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	.logo-box {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50vw;
		width: 100vw;
	}

	.logo-box img {
		width: 58vw;
	}

	.login-bg-box {
		background: #fff;
		border-radius: 10px;
		width: 92vw;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-wrap: wrap;
		margin: 0 auto;
		padding: 0 3.5vw;
	}

	.login-bg-box input[type="text"],
	.login-bg-box input[type="number"] {
		width: 100%;
		height: 1rem;
		line-height: 1rem;
		border-radius: 0.1rem;
		background: #f5f5f5;
		padding: 0 15px;
		font-size: 0.28rem;
		border: none;
		margin-bottom: 0.33rem;
	}

	.login-bg-box .btn {
		background: #e50012;
		width: 100%;
		height: 0.84rem;
		line-height: 0.84rem;
		text-align: center;
		border-radius: 0.42rem;
		color: #fff;
		margin: 0.5rem 0 0.5rem;
		font-size: 0.3rem;
	}

	.login-bg-box input.code-input {
		width: 56vw;
	}

	.login-bg-box .get-code {
		display: flex;
		width: 1.78rem;
		align-items: center;
		justify-content: center;
		background: #e50012;
		color: #fff;
		font-size: 0.28rem;
		height: 1rem;
		border-radius: 0.1rem;
		margin-bottom: 0.33rem;
	}

	.get-code.disable {
		background-color: #999;
	}

	.agree-row {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #949494;
		font-size: 0.26rem;
		width: 85vw;
		margin-top: 30px;

		.check {
			width: 0.24rem;
			height: 0.24rem;
			background: url("../../../assets/images/check.png") no-repeat center;
			background-size: cover;
			margin-right: 0.1rem;

		}

		.active {
			background-image: url("../../../assets/images/checked.png");
		}
	}

	.agree-row a {
		color: #e50012;
	}

	.agree-row input {
		margin-right: 5px;
	}

	.wrapper {
		width: 80vw;
		height: 80vh;
		background: #fff;
		margin: 10vh auto;
		border-radius: 0.1rem;
		overflow-y: auto;
		padding: 0.24rem;
		box-sizing: border-box;

		.applypromoter {
			padding: 0;
		}
	}
</style>
